import '../Styles/WaitList.css';
import background from "../Images/log-in-background.png"
import logo from '../Images/logoAR.svg'
import vector from '../Images/Vector.svg'
import Branch from '../Images/Branch.svg'
import { useTranslation } from 'react-i18next';
import { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import enLogo from "../Images/enLogo.svg"

import { BeatLoader } from 'react-spinners';
import { waitingList,readyTable } from '../api';
import useSpeechSynthesis from 'react-speech-kit/dist/useSpeechSynthesis';
function WaitList() {
    const { t, i18n } = useTranslation();

    const { speak,voices } = useSpeechSynthesis();
    const token= localStorage.getItem("token");
    const [loading,setLoading]=useState(true)
    const [text,setText]=useState("")
    const [className,setClassName]=useState("order")

    const [tableNum,setTableNum]=useState(0)

   const [orderNum,setOrderNum]=useState(0)

   
   const [waitList,setWaitList]=useState([])
const [servicedTables,setServicedTables]=useState([])
   
   

   
//user must set sound settings to allow
 
    useEffect(() => {
      let count=0;

      
      if(count==0){
      
        
        waitingList(token).then(response => {
   
          setWaitList(response.data.data.waitingOrders)
          setServicedTables(response.data.data.servicedTables)
     setLoading(false)
    
         })

        count ++;

      }
     
        const interval = setInterval(() => {

         waitingList(token).then(response => {
   
          setWaitList(response.data.data.waitingOrders)
          setServicedTables(response.data.data.servicedTables)
     
           })

          readyTable(token, localStorage.getItem("tableBranchId")).then(response => {
       
            if(response.data.data!==tableNum && response.data.data.length!==0){

           
           
              setTableNum(response.data.data.tableName)
              setOrderNum(response.data.data.orderId)
              setText(`عميل رقم ${response.data.data.orderId} برجاء التوجه الي جلسة رقم  ${response.data.data.tableName}`)
           
     
            setClassName("order test")
            setTimeout(function () {
              document.getElementById("speak").click();

          }, 1000);
            
          
          }
          })

           

        },10000);

        return () => clearInterval(interval);
        
      },[])
   
  

      useEffect(() => {
    
      

        const data=  JSON.parse(localStorage.getItem("language"));
        i18n.changeLanguage(data);
      if(i18n.language==="en"){
            document.getElementById("root").style.direction="ltr";
         
            }
            else if(i18n.language==="ar"){
              document.getElementById("root").style.direction="rtl";
            
            }
            else{
             document.getElementById("root").style.direction="rtl";
            }
        
            if(JSON.parse(localStorage.getItem("language"))==="ar"){
             document.title=" الرومانسية كاشير"
         }else {
             document.title="AlRomansiah Cashier"
        
         }
               },[]);
    return ( 
      <div className='container-fluid' style={i18n.language=="ar"?{paddingRight:"2em"}:{paddingLeft:"2em"}}>
          {loading?
               <div style={{height:"100vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <div className="row">
      
       <div className='mainDiv mainDiv2 col' >
         <div className='waitList'>
      <div className='logo'>   
    <Link to="/add-reservation">
  <div style={i18n.language=="ar"?{ backgroundImage: `url(${logo})` }:{ backgroundImage: `url(${enLogo})` }}>
  
  </div></Link>
  
  </div>  
  <div className='container-fluid'>
    <div className='row' >
  <div className='orderNumber col-5' style={waitList.length==0?{display:"none"}:{}} >
  
  <div className='container-fluid p-0'>
  <div className='row gy-3 row1' style={{textAlign:"left"}}>
  
  <div className='col-12' style={{marginTop:"0"}}>
          {/*  <button onClick={() => speak({ text: "عميل رقم A-134 برجاء التوجه الي جلسة رقم  ",voice:voices[23] })}>Speak</button>
      */}
         <button id="speak" onClick={() => speak({ text: text,voice:voices[23] })}></button>
  <p className='titleW'>{t("menu.waiting")} </p></div>
   
 
 
  <div className='col-12 titles '>
    <p> {t("tables.orderNo")}</p></div>
   
  
  {waitList && waitList.map((data,index)=>
 
  <div className='col-12' key={index}>
  <div  className='number'>
  <p>{data}</p></div>
  </div>
    
  

  )}
 
 
  
  </div>
  
  </div>
  </div>




  <div className='orderNumber col-7' >
  
  <div className='container-fluid p-0'>
  <div className='row gy-3 row2' style={{textAlign:"left"}}>
  
 
   
  <div className='col-12'  style={{marginTop:"0"}}>
  <p className='titleW' style={{textAlign:"center"}}>{t("tables.readyTable")} </p>

  </div>
 

    <div className='col-6 titles '>
    <p> {t("tables.orderNo")}</p></div>
    <div className='col-6 titles '>
    <p> {t("tables.tableNum")}</p></div>

    
  
  {servicedTables && servicedTables.map((data,index)=>
 <>
  <div className='col-6' key={index}>
  <div  className='number'>
  <p>{data.orderId}</p></div>
  </div>
    <div className='col-6' key={index}>
    <div  className='number'>
    <p>{data.tableName}</p></div>
    </div>
  
    </>
  )}
 
 
  
  </div>
  
  </div>
  </div>
  </div> </div>


  </div>
  
         </div>
         <div className='mainDiv col' style={{textAlign:"center",position:"relative"}} >
         
         <img src={vector} id="vector" className={i18n.language=="en"?"enVector":null}/>
         
         <div className={className}>
  <p className='mainTitle'>إدارة الجلسات</p>
  <p className='brnchName'> {i18n.language=="ar"?localStorage.getItem("tableBranchAr") :localStorage.getItem("tableBranchEn")} </p>
  
  <div>
    <p className='p1'>عميل رقم {orderNum} برجاء التوجه الي<br/> جلسة رقم</p>
    <p style={{fontSize:"4.5vw",lineHeight:"1"}}>{tableNum}</p>
  </div>
  
  </div>
       <div>
  
       </div>
       <div className={i18n.language=="ar"?'brnchImg' :'brnchImg brnchImgEn'} style={{ backgroundImage: `url(${Branch})` }}>
  
       </div>
       </div>
         </div>}
      </div>
    );
  }
  
  export default WaitList;
  
  