import '../Styles/Offers.css';
import { useTranslation } from 'react-i18next';

import { useState,useEffect ,useRef} from 'react';
import { BeatLoader } from 'react-spinners';
import { calories,filterMeals } from '../api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import Select from "react-select";
import {GrPowerReset}  from "react-icons/gr";
function Calories() {
    const { t, i18n } = useTranslation();
  const[loading,setLoading]=useState(true)
  const[menu,setMenu]=useState([])
  const[page,setPage]=useState(1)
  const[resp,setResp]=useState()
  const[products,setProducts]=useState([])
  
  const[selectedName,setSelectedName]=useState({value:""})

  const[tableLoading,setTableLoading]=useState(false)
  const[filter,setFilter]=useState()
  const nameRef=useRef()

const categRef=useRef()
const sizeRef=useRef()
const addRef=useRef()
const prodRef=useRef()


//filterMeals api call gets data used in filteration
//calories api  call gets data of meals
  useEffect(() => {

    const token= localStorage.getItem("token");
    let params={
      page:page,
      applicationId:4
    }
      setLoading(true)
      filterMeals(token).then((response) => {
        let items = [];  
         
        for (let i = 0; i < response.data.data.products.length; i++) {  
            let obj = {};
            obj.label =  i18n.language=="ar"?response.data.data.products[i].arName : response.data.data.products[i].enName;
            obj.value = response.data.data.products[i].id;       
            items.push(obj);   
             //creating  options dynamically based on what props are currently passed to the parent component
        }
        setProducts(items)
        setFilter(response.data.data)

      })
      calories(token,params).then((response) => {
       
      
        setResp(response.data.data)
        if( response.data.data.toString()==[]){
          setMenu([])
          setLoading(false)
 
         }
    else{
        const result = Object.values(response.data.data.data);

 
        let mealArr = [];
    
        for (let i = 0; i < result.length; i++) {  
            let obj = {};
            obj.mealId = result[i].mealId  
            obj.productArName =<p style={{marginBottom:"0"}}>{result[i].productArName}  </p> 
            obj.productEnName =<p style={{marginBottom:"0"}}>{result[i].productEnName} </p> 
            obj.categArName = result[i].category.arName
            obj.categEnName = result[i].category.enName
            obj.sizeArName = result[i].sizeArName
            obj.sizeEnName = result[i].sizeEnName
            obj.additionalArName = result[i].additionalArName      
            obj.additionalEnName = result[i].additionalEnName;        
            obj.code = result[i].code       
            obj.price = result[i].price    
            obj.aggregatorsPrice =result[i].aggregatorsPrice 
            obj.calories =result[i].calories   
            mealArr.push(obj);  } 
      
            setMenu(mealArr)
            setLoading(false)
 
          
          }

    })},[])


 
  const data = {
    columns: [
        {
            label: t("offer.id"),
            field: "mealId",
            sort: 'asc',
       
          },

          {
            label: t("menu.category"),
            field: i18n.language=="ar"?"categArName":"categEnName",
            sort: 'asc',
       
          },
      {
        label: t("offer.prodName"),
        field: i18n.language=="ar"?"productArName":"productEnName",
        sort: 'asc',
   
      },
    
      {
        label: t("offer.size"),
        field: i18n.language=="ar"?"sizeArName":"sizeEnName",
        sort: 'asc',
      
      },

      {
        label: t("offer.additional"),
        field:  i18n.language=="ar"?"additionalArName":"additionalEnName",
        sort: 'asc',
      
      },
      {
        label:  t("offer.code"),
        field: 'code',
        sort: 'asc',
       
      },
      {
        label: t("offer.price"),
        field: 'price',
        sort: 'asc',
       
      },
      {
        label:t("offer.agg"),
        field: 'aggregatorsPrice',
        sort: 'asc',
       
      },
     
      {
        label: t("offer.cal"),
        field: 'calories',
        sort: 'asc',
      
      }
   
    
     
    
     
    ],    rows: menu
    
};  

//on page change or on filtering
//on page change => changes page
//on filtering => set page to page number 1
//calories api call can filter by (categoryId,productId,additionalId and sizeId)
function filterData(page){
  const token= localStorage.getItem("token");
  window.scrollTo(0, 0);
  setTableLoading(true)
  setPage(page)

   let params={
    page:page,
    applicationId:4,
    categoryId:categRef.current.value,
productId:selectedName.value, 
additionalId:addRef.current.value,
sizeId:sizeRef.current.value
  }
      /* let params={
    page:page,
    applicationId:4,


  }*/

  calories(token,params).then((response) => {
 
  
    setResp(response.data.data)
    if( response.data.data.toString()==[]){
      setMenu([])
      setTableLoading(false)

     }
else{
    const result = Object.values(response.data.data.data);


    let mealArr = [];

    for (let i = 0; i < result.length; i++) {  
        let obj = {};
        obj.mealId = result[i].mealId  
        obj.productArName =<p style={{marginBottom:"0"}}>{result[i].productArName}  </p> 
        obj.productEnName =<p style={{marginBottom:"0"}}>{result[i].productEnName} </p> 
        obj.categArName = result[i].category.arName
        obj.categEnName = result[i].category.enName
        obj.sizeArName = result[i].sizeArName
        obj.sizeEnName = result[i].sizeEnName
        obj.additionalArName = result[i].additionalArName      
        obj.additionalEnName = result[i].additionalEnName;        
        obj.code = result[i].code       
        obj.price = result[i].price    
        obj.aggregatorsPrice =result[i].aggregatorsPrice 
        obj.calories =result[i].calories   
        mealArr.push(obj);  } 
  
        setMenu(mealArr)
        setTableLoading(false)}


})

}

//clears input fields
//calories api is called to get areas with no filtres
//sets page to page number 1
function resetData(){


  var selectElement = document.getElementsByClassName("calSelect");

  for (var i = 0; i < selectElement.length; i++) {
     selectElement[i].selectedIndex =null

  }
 
  setSelectedName(null)



  const token= localStorage.getItem("token");
  window.scrollTo(0, 0);
  setTableLoading(true)
  setPage(1)

  let params={
    page:1,
    applicationId:4,

  }
  /*
  let params={
    page:page,
    applicationId:4,


  }*/

  calories(token,params).then((response) => {
 
  
    setResp(response.data.data)
    if( response.data.data.toString()==[]){
      setMenu([])
      setTableLoading(false)

     }
else{
    const result = Object.values(response.data.data.data);


    let mealArr = [];

    for (let i = 0; i < result.length; i++) {  
        let obj = {};
        obj.mealId = result[i].mealId  
        obj.productArName =<p style={{marginBottom:"0"}}>{result[i].productArName}  </p> 
        obj.productEnName =<p style={{marginBottom:"0"}}>{result[i].productEnName} </p> 
        obj.categArName = result[i].category.arName
        obj.categEnName = result[i].category.enName
        obj.sizeArName = result[i].sizeArName
        obj.sizeEnName = result[i].sizeEnName
        obj.additionalArName = result[i].additionalArName      
        obj.additionalEnName = result[i].additionalEnName;        
        obj.code = result[i].code       
        obj.price = result[i].price    
        obj.aggregatorsPrice =result[i].aggregatorsPrice 
        obj.calories =result[i].calories   
        mealArr.push(obj);  } 
  
        setMenu(mealArr)
        setTableLoading(false)}


})

}
    return (
      <div className='questions'> 
   <p style={{marginBottom:"0"}} className='screenTitle'>{t("profile.calories")}</p>

{loading?
               <div style={{height:"60vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
   <div className={i18n.language=="ar"?'container-fluid  pagAr':"container-fluid"}>

     <div className='container-fluid filtering' style={{marginBottom:"2em",padding:"1em 0"}}>
     

     <div className='row '>
     
     <div className='col-12'>
     <div className='container-fluid'>
     <div className='row gy-3'>
     <div className='col-12 filterTitile'>
       <p >{t("orders.filter")}</p>
     </div>
     
     <div className='col-lg-4 col-md-3 col-sm-6'>
     <label>{ t("menu.product")}</label>
  
     <Select isClearable placeholder={ t("offer.search")}  onChange={(e)=> setSelectedName(e) } value={selectedName}  options={products}/>
       </div>

     <div className='col-lg-2 col-md-3 col-sm-6'>
     <label>{t("menu.category")}</label>
  
       <select defaultValue="" ref={categRef} className="calSelect">
             
     <option value="" disabled>{t("profile.choose")}</option>
     <option value={""} >{t("areas.all")}</option>
     {filter&& filter.categories.map((data,index)=>
  <option key={index} value={data.id} >{i18n.language=="ar"?data.arName:data.enName}</option>
)}
   
               </select>
       </div>

     <div className='col-lg-2 col-md-3 col-sm-6'>
     <label>{t("offer.size")}</label>
     
   
       <select defaultValue="" onChange={()=>console.log(sizeRef.current.value)} ref={sizeRef} className="calSelect">
             
     <option value="" disabled>{t("profile.choose")}</option>
     <option value={""} >{t("areas.all")}</option>
     {filter && filter.sizes.map((data,index)=>
  <option key={index} value={data.id} >{i18n.language=="ar"?data.arName:data.enName}</option>
)}
   
               </select>
     
     </div>
     <div className='col-lg-2 col-md-3 col-sm-6'>
     <label>{t("offer.additional")}</label>
     
       <select defaultValue="" ref={addRef} className="calSelect">
             
     <option value="" disabled>{t("profile.choose")}</option>
     <option value={""} >{t("areas.all")}</option>
     {filter && filter.additionals.map((data,index)=>
  <option key={index} value={data.id} >{i18n.language=="ar"?data.arName:data.enName}</option>
)}
   
               </select>
      
     </div>
     

       <div className='col-lg-4 col-md-6 alignVertEnd ' style={{textAlign:"end"}}>
     <button  style={{width:"35%"}}  disabled={tableLoading} onClick={()=>filterData(1)} className='filter'>{t("orders.filter")}</button>
   <span style={{margin:"0 0.5em"}}></span>
     <button style={{width:"65%"}}   onClick={resetData}  className='reset'>{t("offer.reset")} <GrPowerReset color='#ffff'/></button>

     </div>

    
       </div>
       </div>
     </div>
     </div>
     </div>
    
{tableLoading?
               <div style={{height:"30vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={tableLoading}  size={30} /></div>:
      <>
          <div style={{marginTop:"1em"}} className={i18n.language=="ar"?"row orderTable":"row tableEn orderTable"}>
                            <div className={ menu.length==0? i18n.language=="ar"?" emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"  empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"col-lg-12 col-md-12 col-sm-12 col-xs-12" }>
                                <MDBDataTable
                                entries={50}
                                entriesOptions={[50]}
                                infoLabel={[t("orders.info1"), t("orders.info2"), t("orders.info3"), t("orders.info4")]} 
                                searchLabel={t("dashboard.search")}
                                paginationLabel={ [t("orders.prev"), t("orders.next")]}
                                entriesLabel={t("orders.show")}
                                    striped
                                    hover
                                    noBottomColumns={true}
                                    data={data}
                                />  
                            </div>
                        </div>
                   <Stack spacing={0}>
      <Pagination 
      
       
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
          {...item}
        />)}
    
      className={i18n.language=="ar"?'arArrow':null} defaultPage={1} page={page}  count={resp.last_page} onChange={(e,value)=>{ filterData(value)}} shape="rounded" variant="outlined" 
      
      />
      </Stack></>}
                        </div>}

       </div>
    );
  }
  
  export default Calories;
  
  