import AxiosProvider from "./AxiosProvider";
import AxiosProvider2 from "./AxiosProvider2";

export const login = (values) => {
  return AxiosProvider.post("customer-service/login", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const changeOnlineStatus = (values, token) => {
  return AxiosProvider.post("customer-service/update-chat-status", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const addUser = (token, values) => {
  return AxiosProvider.post("users", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const meals = (token) => {
  return AxiosProvider.get("meals", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const waitingList = (token) => {
  return AxiosProvider.get("customer-service/waiting-tables", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const search = (token, params) => {
  return AxiosProvider.get("users/listByPhone/", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params,
  });
};

export const filters = (token) => {
  return AxiosProvider.get("/orders/filter-data", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      applicationId: 4,
    },
  });
};
export const filterMeals = (token) => {
  return AxiosProvider.get("/meals/calories-filters", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const register1 = (token, id, values, lang) => {
  return AxiosProvider.put(`external-sales/${id}/postpaid/first-step`, values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,

      Accept: "application/json",
    },
    params: {
      lang: lang,
    },
  });
};
export const register2 = (token, id, values, lang) => {
  return AxiosProvider.put(
    `external-sales/${id}/postpaid/second-step`,
    values,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,

        Accept: "application/json",
      },
      params: {
        lang: lang,
      },
    }
  );
};
export const register3 = (token, id, values, lang) => {
  return AxiosProvider.post(
    `external-sales/${id}/postpaid/third-step`,
    values,
    {
      headers: {
        Authorization: "Bearer " + token,

        "Content-Type": "undefined",
        Accept: "application/json",
      },
      params: {
        lang: lang,
      },
    }
  );
};

export const areas = (token, cityId, branchId, page, areaName) => {
  return AxiosProvider.get("cities/all/areas", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      cityId: cityId,
      branchId: branchId,
      page: page,
      areaName: areaName,
    },
  });
};

export const district = (token) => {
  return AxiosProvider.get("districts", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const myOrders = (token, params) => {
  return AxiosProvider.get("customer-service/orders", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params,
  });
};

export const getNews = (token, id) => {
  return AxiosProvider.get("/news", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const singleOrder = (token, id) => {
  return AxiosProvider.get("orders/" + id, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const countries = (token) => {
  return AxiosProvider.get("countries", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const customer = (token, id) => {
  return AxiosProvider.get("users/" + id + "/customers-classifications", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const customerOrders = (token, id, params) => {
  return AxiosProvider.get("users/" + id + "/orders", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params,
  });
};

export const customerComplaints = (token, id) => {
  return AxiosProvider.get("users/" + id + "/complaints", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const customerWallet = (token, id, page) => {
  return AxiosProvider.get("users/" + id + "/wallet-transactions", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    params: {
      page: page,
    },
  });
};

export const customerAwafiWallet = (token, id, page) => {
  return AxiosProvider.get("users/" + id + "/wallet-awafi-transactions", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    params: {
      page: page,
      
    },
  });
};

export const customerBalance = (token, id) => {
  return AxiosProvider.get("users/" + id + "/wallet-all-balance", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const customerAwafiBalance = (token, id) => {
  return AxiosProvider.get("users/" + id + "/wallet-balance", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const customerPoints = (token, id) => {
  return AxiosProvider.get("users/" + id + "/points", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getAwafiCode = (token, id, values) => {
  return AxiosProvider.post("users/" + id + "/issue-awafi-code", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};
export const changePoints = (token, id, values) => {
  return AxiosProvider.post("users/" + id + "/change-points", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const customerAddresses = (token, id, now, date, time) => {
  return AxiosProvider.get("users/" + id + "/addresses", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      applicationId: 4,
      now: now,
      date: date,
      time: time,
    },
  });
};
export const autoRepliesList = (token) => {
  return AxiosProvider.get("/chats/automatic/replies", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const delAddresses = (token, id) => {
  return AxiosProvider.delete("addresses/" + id, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const cities = (token) => {
  return AxiosProvider.get("cities", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
/*
export const cityAreas = (token,cityId) => {
  return AxiosProvider.get('cities/'+ cityId+'/areas',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' +token,
      }
  
    }
      
      )
}*/
export const branches = (token, cityId) => {
  return AxiosProvider.get("branches", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      type: "city",
      class: "branch",
      cityId: cityId,
      applicationId: 4,
    },
  });
};

export const areaByBranch = (token, branchId) => {
  return AxiosProvider.get(`branches/${branchId}/areas`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};

export const orderBranches = (token, params) => {
  return AxiosProvider.get("branches", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params,
  });
};

export const addAddress = (token, id, values) => {
  return AxiosProvider.post("users/" + id + "/address", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const addComplaint = (token, id, values) => {
  return AxiosProvider.post("users/" + id + "/complaint", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const editCustomer = (token, id, values) => {
  return AxiosProvider.post("users/" + id + "/edit", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const allBranches = (token) => {
  return AxiosProvider.get("branches", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};

export const saveLoyalty = (token, id, values) => {
  return AxiosProvider.post(`users/${id}/save-loyalty`, values, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

export const tables = (token, id, type) => {
  return AxiosProvider2.get("cashier/tables", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

export const isAvailTable = (token, branchId, tableId) => {
  return AxiosProvider.get("branches/" + branchId + "/check-table", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      tableId: tableId,
    },
  });
};

export const closeTable = (token, tableId, values) => {
  return AxiosProvider.post("tables/" + tableId + "/use", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};
export const openTable = (token, tableId, values) => {
  return AxiosProvider.post("tables/" + tableId + "/free", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const types = (token) => {
  return AxiosProvider.get("types/list", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

export const sources = (token) => {
  return AxiosProvider.get("sources/list", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const categories = (token, typeId) => {
  let params = {
    applicationId: 4,
    typeId: typeId,
  };

  return AxiosProvider2.get("categories", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params,
  });
};







export const products = (token, categoryId, branchId, sourceId) => {
  let params = {
    categoryId: categoryId,
    branchId: branchId,
    source: "agent",
    sourceId: sourceId,
    type: "",
    applicationId: 4,
    categoryRecommended:true,
    // categoryMessage:true
  };

  return AxiosProvider.get("products", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params,
  });
};

export const product = (token, productId, branchId, sourceId, userId) => {
  let params = {
    branchId: branchId,
    sourceId: sourceId,
    applicationId: 4,
    source: "agent",
    
  };

  return AxiosProvider2.get("products/" + productId, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params,
  });
};

export const getPromoCodes = (token, params) => {
  

  return AxiosProvider2.get("promo-codes/", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params,
  });
};

export const getAvaliableOffers = (token, params) => {
  

  return AxiosProvider2.get("offers/", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params,
  });
};

export const addToCart = (token, data) => {
  return AxiosProvider.post(`/cart`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};
export const block = (token, userid, data) => {
  return AxiosProvider2.post(`/users/${userid}/block`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};
export const changePaymentMethod = (token, orderId, data) => {
  return AxiosProvider2.put(`/orders/${orderId}/payment-method`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};
export const Get_Basket = (token, userId, sourceId, accountType) => {
  let params = {
    userId: userId,
    sourceId: sourceId,
    accountType: accountType,
    applicationId: 4,
  };
  return AxiosProvider.get(`/cart`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },

    params,
  });
};

export const Clear_cart = (cartId, token) => {
  return AxiosProvider.delete(`/cart/${cartId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const Edit_Quantity = (cartId, mealId, quantity, token) => {
  var body = {
    quantity: quantity,
  };
  return AxiosProvider.put(`/cart/${cartId}/meal/${mealId}/quantity`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const Delete_Item = (cartId, id, token) => {
  return AxiosProvider.delete(`/cart/${cartId}/meal/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const check_offer = (data, token, sourceId) => {
  return AxiosProvider.post(`/cart/offer`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,

      sourceId: sourceId,
    },
  });
};

export const partial_pyament = (orderId, data,token) => {
  return AxiosProvider2.post(`/orders/${orderId}/add-partial-payment`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,

   
    },
  });
};




export const create_order_offline = (products, phone, notes, paymentMethod) => {
 
  const platform = "agent";
  const token = localStorage.getItem("token");
  
  console.log(`paymentMethod ${paymentMethod}`)

  var body = {
    paymentMethod: paymentMethod == "awafi"
    ? "expirable_wallet": paymentMethod == "shabka"? "intrasoft" : paymentMethod,
    notes: `${notes == null ? "": notes}, ${localStorage.getItem("notesCheckbox") == null ? "":localStorage.getItem("notesCheckbox")}`,
    date: " ",
    now: "true",
    time: "",
    branchId: parseInt(localStorage.getItem("branchId")),
    typeId: parseInt(localStorage.getItem("orderType")),
    products: products,
    platform: platform,
    phone: phone,
    userId: parseInt(localStorage.getItem("userId")),
    tableId: parseInt(localStorage.getItem("selectedTable")),
    canceledOrderId: localStorage.getItem("cancelOrderId")
        ? parseInt(localStorage.getItem("cancelOrderId"))
        : "",

  };

  return AxiosProvider.post(`/orders`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,
      sourceId:7
    },
  }).then((res) => {
    return res;
  });
};


export const create_order = (data, token) => {
  return AxiosProvider.post(`/cart/order`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};

export const invoiceData = (orderId, token) => {
  return AxiosProvider.get(`/orders/${orderId}/A/invoice`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const accept_order = (data, token, orderId) => {
  return AxiosProvider.put(`/orders/${orderId}/make-accepted`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};

export const awafiBalance = (token, phone) => {
  return AxiosProvider2.get(`users/${phone}/wallets/`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params:{
      applicationId: 4,
      
    }
  });
};


export const edit_Type = (token, typeId, cartId) => {
  let body = {
    typeId: typeId,
  };
  return AxiosProvider.put(`/cart/${cartId}/type`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const edit_Address = (token, addressId, cartId) => {
  let body = {
    addressId: addressId,
  };
  return AxiosProvider.put(`/cart/${cartId}/address`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const edit_order = (token, orderId, userId) => {
  return AxiosProvider.post(
    `orders/${orderId}/repeat`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      params: {
        userId: userId,
      },
    }
  );
};

export const branachByArea = (token, areaId) => {
  return AxiosProvider.get(`areas/${areaId}/branches`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      applicationId: 4,
    },
  });
};

export const readyTable = (token, branchId) => {
  let params = {
    branchId: branchId,
  };
  return AxiosProvider.get(`/customer-service/ready-table`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const payment_methods = (cartId, delivery, sourceId, token) => {
  let params = {
    deliveryType: delivery,
    sourceId: localStorage.getItem("type") == "cashier" ? 7 : sourceId,
    applicationId: 4,
  };
  return AxiosProvider.get(`/cart/${cartId}/payment-methods`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const editAddress = (token, addressId, data) => {
  return AxiosProvider.post(`addresses/${addressId}`, data, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

export const cancel_direct = (id, token) => {
  let body = {
    type: "canceled",
  };
  return AxiosProvider.put(`orders/${id}`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const calories = (token, params) => {
  return AxiosProvider.get("/meals", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params,
  });
};
export const banners = (token, lang) => {
  return AxiosProvider.get("/banners", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      language: lang,
    },
  });
};

export const questions = (token, lang) => {
  return AxiosProvider.get("questions/questions", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      language: lang,
    },
  });
};

export const editAdvPayment = (body, token, lang) => {
  return AxiosProvider.put(`/external-sales/1`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      lang: lang,
    },
  });
};

export const changeStatus = (code, values, token, lang) => {
  return AxiosProvider.post(`external-sales/${code}/change-status`, values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      lang: lang,
    },
  });
};
export const listExternalUsers = (token, page) => {
  return AxiosProvider.get(`external-sales`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      page: page,
    },
  });
};

export const dashboardImg = (token) => {
  return AxiosProvider.get(`/infos/cahsier-intro-bg`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      type: "cashier",
    },
  });
};

export const externalUser = (token, id) => {
  return AxiosProvider.get(`external-sales/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const updatePrepayment = (token, id, values, lang) => {
  return AxiosProvider.put(`external-sales/${id}/prepayment`, values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      lang: lang,
    },
  });
};
export const updatePostpayment = (id, values, token, lang) => {
  return AxiosProvider.post(
    `external-sales/${id}/postpaid/first-step`,
    values,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      params: {
        lang: lang,
      },
    }
  );
};

export const companyActivities = (token) => {
  return AxiosProvider.get(`/external-sales/get-activities`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,

      "Content-Type": "application/json",
    },
  });
};
export const activityTypes = (token) => {
  return AxiosProvider.get(`/external-sales/get-facility-types`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,

      "Content-Type": "application/json",
    },
  });
};

export const registerAdv = (token, id, values, lang) => {
  return AxiosProvider.put(`/external-sales/${id}`, values, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {
      lang: lang,
    },
  });
};

export const updateBasicData = (token, id, values, lang) => {
  return AxiosProvider.put(
    `/external-sales/${id}/update-register-info`,
    values,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        lang: lang,
      },
    }
  );
};

export const sendCode = (token, id) => {
  return AxiosProvider.put(
    `external-sales/delegate/verification-code/${id}`,
    null,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,

        Accept: "application/json",
      },
    }
  );
};
export const otp = (token, id, code, lang) => {
  return AxiosProvider.get(`external-sales/verify-code/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,

      Accept: "application/json",
    },
    params: {
      verificationCode: code,
      lang: lang,
    },
  });
};

export const sendMessage = (token, message) => {
  return AxiosProvider.post("/chats", message, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const chatHistory = (token, userID, page) => {
  return AxiosProvider.get(`/chats/chatHistory/${userID}/?page=${page}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const chatList = (token, userID, params) => {
  return AxiosProvider.get(`/chats/chatList/${userID}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params,
  });
};
export const singleUser = (token, params) => {
  return AxiosProvider.get(`chats/messages`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params,
  });
};

export const countUnread = (token, id) => {
  return AxiosProvider.get(`chats/unreadChatsCount/${id}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const changeLimit = (token, id, values) => {
  return AxiosProvider.post(
    `external-sales/${id}/postpaid/third-step`,
    values,
    {
      headers: {
        Authorization: "Bearer " + token,

        "Content-Type": "undefined",
        Accept: "application/json",
      },
    }
  );
};

export const getDocuments = (token, id, page) => {
  return AxiosProvider.get(`external-sales/${id}/all-requests`, {
    headers: {
      Authorization: "Bearer " + token,

      Accept: "application/json",
    },
    params: {
      page: page,
    },
  });
};
export const uploadDocument = (token, id, values, lang) => {
  return AxiosProvider.post(
    `external-sales/${id}/uploadRequestAttachments`,
    values,
    {
      headers: {
        Authorization: "Bearer " + token,

        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      params: {
        lang: lang,
      },
    }
  );
};
export const requestDoc = (token, id, values, lang) => {
  return AxiosProvider.post(`external-sales/${id}/make-request`, values, {
    headers: {
      Authorization: "Bearer " + token,
      language: lang,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    params: {
      lang: lang,
    },
  });
};
export const externalCategories = (token) => {
  return AxiosProvider.get(`/categories?applicationId=6`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const singleExternalCategories = (token, userId) => {
  return AxiosProvider.get(`/external-sales/${userId}/categories`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const changeExternalCategories = (token, userId, values, lang) => {
  return AxiosProvider.post(
    `/external-sales/${userId}/add-categories`,
    values,
    {
      headers: {
        Authorization: "Bearer " + token,
        language: lang,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      params: {
        lang: lang,
      },
    }
  );
};

export const productionList = (token, branchId, page, data) => {
  return AxiosProvider.get(`/products/production/manager`, {
    headers: {
      Authorization: "Bearer " + token,

      Accept: "application/json",
    },
    params: {
      branchId: branchId,
      page: page,
      categoryId: data?.categoryId,
      productName: data?.productName,
    },
  });
};

export const agnetBranches = (token) => {
  return AxiosProvider.get("/branches/related-to-customer-service", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

export const saveNewQuan = (token, values) => {
  return AxiosProvider.post(`/products/update/quantity`, values, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const getCategoryByBranch = (token, id) => {
  return AxiosProvider.get(`/categories/getCategoriesByBranch?branchId=${id}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

export const getOrganizations = (token, userId) => {
  return AxiosProvider.get("/user-organizations", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
    params: {
      userId: userId,
    },
  });
};

export const addOrganization = (token, data) => {
  return AxiosProvider.post("/user-organizations", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const getProducts = (token, categoriesIds) => {
  return AxiosProvider.get(
    `/products/getProductsByCategoriesIds/${categoriesIds}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    }
  );
};

export const getExternalProducts = (token, id) => {
  return AxiosProvider.get(`external-sales/${id}/products`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};
export const getUserProfile = (token, from = null, to = null) => {
  let url = "/report";
  url += `?from=${from}&to=${to}`;

  return AxiosProvider2.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const getStimulatingProduct = (token, from = null, to = null) => {
  let url = "/report/stimulating-products";
  url += `?from=${from}&to=${to}`;

  return AxiosProvider2.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};

export const updateExternalProducts = (token, id, body) => {
  return AxiosProvider.post(`external-sales/${id}/add-products`, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  });
};
