import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { invoiceData } from "../api";
import "../Styles/InvoicePdf.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import invoiceImg from "../Images/invoice.png";

const InvoicePage = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const location = useLocation();
  const initialInvoice = location.state?.invoice;
  console.log(initialInvoice);
  let history = useNavigate();
  const { t, i18n } = useTranslation();

  const getInvoice = () => {
    if (!initialInvoice) {
      const orderId = parseInt(localStorage.getItem("orderId"));
      const token = localStorage.getItem("token");
      invoiceData(orderId, token).then((res) => {
        setInvoice(res);
      });
    } else {
      setInvoice(initialInvoice);
    }
  };

  console.log(invoice);

  const getOfflineInvoice = () => {
    const offlineOrders = JSON.parse(localStorage.getItem("offlineOrders"));
    if (offlineOrders && offlineOrders.length > 0) {
      const lastOrder = offlineOrders[offlineOrders.length - 1];
      const formattedInvoice = formatOfflineOrderToInvoice(lastOrder);
      setInvoice(formattedInvoice);
    }
  };

  const formatOfflineOrderToInvoice = (order) => {
    const originalPrice = order.items.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    console.log(originalPrice);
    const tax =  parseInt(localStorage.getItem("branchId")) == 32 ? 5 : 15; // Example tax percentage
    const taxV = tax / 100 + 1;

    const taxValue = parseFloat(((originalPrice / taxV) * tax) / 100).toFixed(
      2
    );

    return {
      invoiceQR: null,
      branchArName: localStorage.getItem("branchArName"), // Adjust as needed
      invoiceAddress: "Address", // Adjust as needed
      branchPhone: "123456789", // Adjust as needed
      taxNumber: "300057172200003", // Adjust as needed
      commercialRegister: "1010214603", // Adjust as needed
      orderCode: order.id,
      date: new Date(order.orderDate).toLocaleDateString(),
      time: new Date(order.orderDate).toLocaleTimeString(),
      createdAt: new Date(order.orderDate).toLocaleDateString(),
      platform: order.platform,
      paymentMethod: order.accountType,
      userName: "", // Adjust as needed
      userPhone: order.phone,
      notes: order.notes || "",
      products: order.items,
      originalPrice: originalPrice,
      discount: 0, // Adjust as needed
      tax: tax,
      taxValue: taxValue,
      deliveryPrice: 0, // Adjust as needed
      walletPay: 0, // Adjust as needed
      totalPrice: originalPrice, // This should be the price without additional tax calculation
      currencyAr: order.items[0].arCurrency,
      currencyEn: order.items[0].enCurrency,
      platform: "الكاشير",
      paymentMethod:localStorage.getItem("selectPayment")
    };
  };
  useEffect(() => {
    if (!initialInvoice) {
      getInvoice();
    } else {
      setInvoice(initialInvoice);
    }
  }, [initialInvoice]);

  useEffect(() => {
    if (!invoice || !navigator.onLine) {
      getOfflineInvoice();
    }
  }, [invoice]);

  useEffect(() => {
    if (imageLoaded) {
      printDiv();
      // history("/order-success");
    }
  }, [imageLoaded]);

  const printDiv = () => {
    let iframe = document.createElement("iframe");

    // Hide the iframe
    iframe.style.display = "none";

    // Append the iframe to the document body
    document.body.appendChild(iframe);

    // Get the document of the iframe
    let iframeDocument = iframe.contentWindow.document;

    let styledPrintDivContent = `
    .table-bordered {
      border-collapse: collapse;
      width: 100%;
    }
    * {
      font-family: 'cairo';
    }
    
    .table-bordered thead th {
      border: 1px solid black;
    }
      p {
  line-height: 1.2; /* You can adjust this value as needed */
}
    
    .table-bordered tbody td {
      border: none !important;
    }
    
    .table-bordered tbody tr {
      border: none !important;
    }
    
    .printDiv{
      max-width: 440px;
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: -1px 1px 4px rgba(51, 51, 51, .2);
      background-color: #fff;
    }
    
    @media print {
      body {
        margin: 0;
        padding: 0;
        font-family: 'cairo';
      }
    
      .table-bordered {
        border-collapse: collapse;
        width: 100%;
      }
      
      .table-bordered thead  {
        border: 1px solid black;
      }
      
      .table-bordered tbody  {
        border: none !important;
      }
      
      .table-bordered tbody  {
        border: none !important;
      }
    
      .invoice-container {
        width: 100%;
        height: 50%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .printDiv{
        max-width: 440px;
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: -1px 1px 4px rgba(51, 51, 51, .2);
        background-color: #fff;
      }
    
      .invoiceDiv {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        background-color: white !important;
      }
    
      .invoiceTableDiv {
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
        border: none;
      }
    
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 0 !important;
        padding: 0 !important;
      }
    
      th, td {
        border: none;
      }
    
      .invoiceSecondTitle,
      .invoiceData {
        width: 100%;
      }
      .invoiceSecondTitle p {
        font-family: 'cairo';
        font-size: 13px;
        font-weight: 900;
        text-align: center;
        margin: 7px;
        padding: 0;
        line-height: 1.2;
      }
    
      .upTable {
        width: 100%;
        border: none;
      }
    
      .invoiceTotal1,
      .invoiceTotal2 {
        display: inline-block;
        width: 100%;
      }
    }
    
    .body .dataDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 900;
      font-size: calc(8px + 1vw);
    
      max-width: 440px;
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: -1px 1px 4px rgba(51, 51, 51, .2);
      background-color: #fff;
    }
    
    .invoiveLogo {
      width: 80%;
      margin: auto;
      padding:auto;
      text-align: center;
      margin-top: 35px
    }
    
    .invoiceData div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .invoiceData {
      border-bottom: 1px solid #e0e0e0;
    }
    
    .invoiceData p {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      letter-spacing: -0.3px;
      color: #4f4f4f;
      margin-bottom: 5px;
      line-height: 1.2; 
    }
    
    .invoiceData .invoice-value {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      letter-spacing: -0.3px;
      color: #331900;
      line-height: 1.2;
    }
    
    .invoiceTitle {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 0px;
      letter-spacing: -0.3px;
      color: #331900;
      text-align: center;
      margin-bottom: 0;
    }
    
    .invoiceTable th {
      text-align: start !important;
      font-weight: 900;
      font-size: 18px;
    }
    
    .invoiceTableDiv {
      box-shadow: none !important;
      padding-bottom: 5px;
      border: none;
    }
    
    .invoiceTotal1 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900 !important;
      font-size: 20px !important;
      line-height: 0px;
      letter-spacing: -0.3px;
      color: #331900 !important;
    }
    
    .invoiceTotal2 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900 !important;
      font-size: 20px !important;
      letter-spacing: -0.3px;
      color: #331900 !important;
    }
    
    .caution {
      text-align: start;
      padding-top: 5px;
    }
    
    .invoiceDiv {
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    
    .invoiceTableDiv .table {
      min-width: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
      border: none;
    }

    
    @media screen and (max-width: 500px) {
      .invoiceTitle {
        font-size: 24px;
        line-height: 0;
        margin-bottom: 5px;
      }
    
      .invoiceData p {
        font-size: 18px;
      }
    
      .invoiceData .invoice-value {
        font-size: 17px;
      }
    
      .invoiceTotal1 {
        font-size: 20px !important;
        line-height: 0px;
        margin-bottom: 0;
      }
    
      .invoiceTotal2 {
        font-size: 23px !important;
      }
    
      .invoiceTable {
        min-width: 0 !important;
      }
    
      .invoiceDiv {
        padding: 13px !important;
      }
    }
    `;

    // Write the content to the iframe document
    iframeDocument.open();
    iframeDocument.write(`
      <!DOCTYPE html>
      <html>
      <head>
          <style>${styledPrintDivContent}</style>
      </head>
      <body>
          ${document.getElementById("printDiv").outerHTML}
      </body>
      </html>
    `);
    iframeDocument.close();

    setTimeout(() => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  return (
    <div className="dataDiv">
      <div className="">
        {invoice != null ? (
          <div id="printDiv" className="container-fluid fluid printDiv">
            <div
              className="vat invoiceDiv"
              style={{ marginBottom: "5em", backgroundColor: "white" }}
            >
              <p className="invoiceTitle">
                {invoice.invoiceQR !== null
                  ? " فاتورة ضريبية مبسطة"
                  : "مسودة فاتورة"}
              </p>
              <img
                onLoad={() => setImageLoaded(true)}
                className="invoiveLogo"
                src={invoiceImg}
              />
              <div className="invoiceSecondTitle">
                <p>شركة الرومانسية مساهمة مقفلة</p>
                <p>الادارة العامة - الرياض - حي السلام - ت -2390770</p>
                <p style={{ direction: "rtl" }}>
                  {" "}
                  {invoice.branchArName} - {invoice.invoiceAddress}{" "}
                </p>
                <p>هاتف - {invoice.branchPhone}</p>
              </div>

              <div className="invoiceData" style={{ direction: "rtl" }}>
                <div>
                  <p>الرقم الضريبي</p>
                  <p className="invoice-value">{invoice.taxNumber}</p>
                </div>

                <div>
                  <p>السجل التجاري</p>
                  <p className="invoice-value">{invoice.commercialRegister}</p>
                </div>
                {invoice.orderCode == null ? null : (
                  <div>
                    <p> رمز الفاتورة</p>
                    <p className="invoice-value">{invoice.orderCode}</p>
                  </div>
                )}

                <div>
                  <p> الرمز المرجعي</p>
                  <p className="invoice-value">
                    {localStorage.getItem("orderId")}
                  </p>
                </div>
             
                  <>
                    <div>
                      <p> التاريخ</p>
                      <p className="invoice-value">{invoice.date}</p>
                    </div>
                    <div>
                      <p> الوقت</p>
                      <p className="invoice-value">{invoice.time}</p>
                    </div>
                  </>
             

                <div>
                  <p>تاريخ الحجز</p>
                  <p className="invoice-value">{invoice.createdAt}</p>
                </div>
                {invoice.platform && (
                  <div>
                    <p> مصدر الطلبية</p>
                    <p className="invoice-value">
                      {invoice && invoice.platform == "agent" ? "الكاشير": invoice.platform}
                    </p>
                  </div>
                )}

                {invoice.paymentMethod && (
                  <div>
                    <p>نوع الدفع</p>
                    <p className="invoice-value">
                      {invoice && invoice.paymentMethod == "expirable_wallet" ? t("profile.awafiWallet"):invoice.paymentMethod }
                    </p>
                  </div>
                )}
                {invoice.userName && (
                  <div>
                    <p>العميل</p>
                    <p className="invoice-value">{invoice.userName}</p>
                  </div>
                )}

                {invoice.userPhone && (
                  <div>
                    <p> الجوال</p>
                    <p className="invoice-value">{invoice.userPhone}</p>
                  </div>
                )}

                {invoice.notes
                  .split(",")
                  .filter(
                    (note) =>
                      note.trim() && note.trim().toLowerCase() !== "null"
                  )
                  .join(", ") && (
                  <div>
                    <p>ملاحظات</p>
                    <p
                      className="invoice-value"
                      style={{
                        maxWidth: "220px",
                        lineHeight: "24px",
                        textAlign: "start",
                      }}
                    >
                      {invoice.notes
                        .split(",")
                        .filter(
                          (note) =>
                            note.trim() && note.trim().toLowerCase() !== "null"
                        )
                        .join(", ")}
                    </p>
                  </div>
                )}
              </div>

              <div
                style={{ direction: "rtl" }}
                className="upTable invoiceTableDiv "
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    border: "1px  #000",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "8px",
                      fontSize: "12px",
                      borderTop: "1px dotted",
                      borderBottom: "1px dotted",
                    }}
                  >
                    <span style={{ flex: 3, textAlign: "right" }}>الوجبة</span>
                    <span style={{ flex: 1 }}>الكمية</span>
                    <span style={{ flex: 1 }}>السعر</span>
                    <span style={{ flex: 1 }}>الاجمالي</span>
                  </div>
                  {invoice.products &&
                    invoice.products.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          fontFamily: "cairo",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "8px",
                          borderBottom: "1px dotted #000",
                          fontSize: "12px",
                        }}
                      >
                        <span style={{ flex: 3, textAlign: "right" }}>
                          {data.arName ?? data.productArName} -{" "}
                          {data.enName ?? data.productEnName}
                          {data.additionals.length !== 0
                            ? " - " +
                              data.additionals
                                .map((item) => item.additionalArName)
                                .join(", ")
                            : " "}
                          {data.extras.length !== 0
                            ? " - " +
                              data.extras
                                .map((item) => item.arName + " ")
                                .join(", ")
                            : "  "}
                        </span>
                        <span style={{ flex: 1 }}>
                          {parseInt(data.quantity)}
                        </span>
                        <span style={{ flex: 1 }}>
                          {!navigator.onLine
                            ? data.price
                            : data.price / data.quantity}
                        </span>
                        <span style={{ flex: 1 }}>
                          {!navigator.onLine
                            ? data.price * data.quantity
                            : data.price}
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div
                className="invoiceData"
                style={{ padding: "1em 0", direction: "rtl" }}
              >
                <div>
                  <p>السعر</p>
                  <p className="invoice-value">
                    {invoice.originalPrice.toFixed(1)} {invoice.currencyAr}
                  </p>
                </div>
                {invoice.discount > 0 ? (
                  <div>
                    <p>الخصم</p>
                    <p className="invoice-value">
                      {parseFloat(invoice.discount).toFixed(1)}{" "}
                      {invoice.currencyAr}
                    </p>
                  </div>
                ) : null}
                <div>
                  <p>{"ضريبة" + " " + invoice.tax + "%"}</p>
                  <p className="invoice-value">
                    {invoice.taxValue} {invoice.currencyAr}
                  </p>
                </div>
                {invoice.typeId == 2 ? (
                  <div>
                    <p>رسوم التوصيل</p>
                    <p className="invoice-value">
                      {invoice.deliveryPrice}{" "}
                      {i18n.language == "ar"
                        ? invoice.currencyAr
                        : invoice.currencyEn}
                    </p>
                  </div>
                ) : null}
                {invoice.walletPay > 0 ? (
                  <div>
                    <p>المدفوع من المحفظة</p>
                    <p className="invoice-value">
                      {invoice.walletPay}{" "}
                      {i18n.language == "ar"
                        ? invoice.currencyAr
                        : invoice.currencyEn}
                    </p>
                  </div>
                ) : null}
                <div>
                  <p className="">المجموع الكلي </p>
                  <p className="invoice-value">
                    {invoice.totalPrice.toFixed(1)} {invoice.currencyAr}
                  </p>
                </div>
                <hr />
                ملاحظة الفاتورة الضريبية ستكون مع المندوب او عند استلام الطلب من
                الفرع
              </div>
            </div>
          </div>
        ) : (
          <p>no data</p>
        )}
      </div>
    </div>
  );
};

export default InvoicePage;

